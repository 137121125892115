import { Button, Divider, Input, Select } from 'antd';
import Style from '../Style';
import _ from 'lodash';
import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import { _deserailize } from './utils';
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { v4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { IOption, IQuestion, MainState } from '../../../../types/state';
import { getAllQuestionsAtPath, getLocalizedText, getQuestionByPath } from '../../../../helpers/utils';
import {
  addChoiceOption,
  removeChoiceOption,
  updateChoiceOptionName,
  updateChoiceOptionValue,
  updateDependencyQuestion,
  updateQuestionChoiceOption,
} from '../../../../redux/QuestionSlice';
import { ListNameMui } from './QuesOptFields';

const { Option } = Select;

interface Props {
  question: IQuestion;
}

export default function Choice({ question }: Props) {
  const dispatch = useDispatch();
  const [isDependencyVisible, setIsDependencyVisible] = useState(false);

  const defaultLanguage = useSelector((state: MainState) => state.language.defaultLanguage);
  const selectedQuestion = useSelector((state: MainState) => state.question.selectedQuestion);

  const questions = useSelector((state: MainState) => state.question.questions);
  const currentQuestion = getQuestionByPath(questions, question.path);

  const choiceQuestions = getAllQuestionsAtPath(question.path, questions);

  const addOption = () => {
    const newOption: IOption = {
      option: '',
      value: '',
      uuid: v4(),
    };

    dispatch(addChoiceOption({ path: selectedQuestion.path, option: newOption }));
  };

  const updateOption = (updatedOption: IOption) => {
    dispatch(updateQuestionChoiceOption({ path: currentQuestion.path, newOption: { ...updatedOption } }));
  };

  const handleUpdateDependencyQuestion = (question: IQuestion | null) => {
    dispatch(updateDependencyQuestion({ path: currentQuestion.path, newQuestion: question }));
  };

  return (
    <Grid container minHeight="300px">
      {currentQuestion.choice.isDefault && (
        <>
          <Grid container spacing={2} marginY={1} alignItems="center">
            <Grid item xs={6}>
              <ListNameMui question={currentQuestion} path={selectedQuestion.path} dispatch={dispatch} />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isDependencyVisible}
                    onChange={() => setIsDependencyVisible(!isDependencyVisible)}
                  />
                }
                label={<Typography variant="body2">Show Dependency Question</Typography>}
              />
            </Grid>
            {isDependencyVisible && (
              <Grid item xs={8}>
                <Autocomplete
                  options={choiceQuestions}
                  value={currentQuestion.dependencyQuestion}
                  onChange={(_, selectedOption) => {
                    handleUpdateDependencyQuestion(selectedOption);
                  }}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <TextField {...params} size="small" label="Dependency Question" />}
                />
              </Grid>
            )}
          </Grid>

          <Divider>
            <Chip label="Options" size="small" />
          </Divider>

          {currentQuestion.choice.options?.map((option: IOption, index: number) => (
            <React.Fragment key={option.uuid || index}>
              <Grid container justifyContent="start" alignItems="start" spacing={1} paddingY={1}>
                {/* Option Input */}
                <Grid item xs={8}>
                  <Stack gap={1} alignItems="start">
                    <Stack direction="row" alignItems="center" width="100%">
                      <TextField
                        size="small"
                        fullWidth
                        label={`Option ${index + 1}`}
                        variant="outlined"
                        value={getLocalizedText(option.option, defaultLanguage.code)}
                        name="option"
                        onChange={(e) =>
                          dispatch(
                            updateChoiceOptionName({
                              path: selectedQuestion.path,
                              optionId: option.uuid,
                              optionName: e.target.value,
                              languageCode: defaultLanguage.code,
                            }),
                          )
                        }
                      />
                    </Stack>
                  </Stack>
                </Grid>

                {/* Value Input */}
                <Grid item xs={3}>
                  <Stack gap={1} alignItems="start" direction="row">
                    <TextField
                      size="small"
                      fullWidth
                      variant="outlined"
                      value={option.value}
                      name="value"
                      onChange={(e) =>
                        dispatch(
                          updateChoiceOptionValue({
                            path: selectedQuestion.path,
                            optionId: option.uuid,
                            value: e.target.value,
                          }),
                        )
                      }
                    />
                    <IconButton
                      onClick={() =>
                        dispatch(
                          removeChoiceOption({
                            path: selectedQuestion.path,
                            optionId: option.uuid,
                          }),
                        )
                      }
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                </Grid>

                {/* Dependency Options */}
                {currentQuestion.dependencyQuestion && (
                  <Grid item xs={12}>
                    <Typography mb={1} variant="subtitle2">
                      Dependency Option
                    </Typography>
                    <Autocomplete
                      multiple
                      options={currentQuestion.dependencyQuestion.choice?.options || []}
                      value={option.dependency?.options || []}
                      getOptionLabel={(opt: any) => opt.option[defaultLanguage.code]}
                      onChange={(_, selectedOptions) => {
                        updateOption({
                          ...option,
                          dependency: {
                            ...option.dependency,
                            options: selectedOptions,
                          },
                        });
                      }}
                      renderInput={(params) => <TextField {...params} size="small" label="Options" />}
                    />
                  </Grid>
                )}
              </Grid>
            </React.Fragment>
          ))}

          <Grid container item justifyContent="center" sx={{ marginRight: '20px', marginTop: '20px' }}>
            <Button type="primary" icon={<PlusOutlined />} size="large" onClick={addOption}>
              Add Choice
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
}
