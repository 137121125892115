import { Box, Divider } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import queryString from 'query-string';
import { Button, Dialog, IconButton, Stack, Tooltip } from '@mui/material';

import Icons from '../../Icons';
import subHeaderStyle from '../SortableContainer/Style';
import LayoutSettings from './LayoutSettings';
import QuestionBank from '../QuestionBank';
import axiosServices from '../../helpers/axiosService';
import { saveURL } from '../../configs/ServerUrl';
import { createGroup } from '../../redux/QuestionSlice';
import { setForm } from '../../redux/FormSlice';
import { checkFormValidation, formatFormName, validateQuestionLabels } from '../../helpers/utils';
import LanguageSelection from '../LanguageSelection';
import toast from 'react-hot-toast';
import { MainState } from '../../types/state';
import { ISubmitForm } from '../../types/payload';
import CascadingSelectQuestions from '../CascadingSelectQuestions';
import DialogTitle from '@material-ui/core/DialogTitle';
// import Eye from '../../Icons/eye.svg';
// import ArrowSort from '../../Icons/arrows-sort.svg';
// import { IconArrowsSort, IconEye } from '@tabler/icons-react';

export default function Header() {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openQuestionBank, setOpenQuestionBank] = useState(false);
  const [openLanguageSelection, setOpenLanguageSelection] = useState(false);
  const [isCascadingModalOpen, setIsCascadingModalOpen] = React.useState(false);

  const classes = subHeaderStyle();
  const dispatch = useDispatch();
  const question = useSelector((state: MainState) => state.question);
  const defaultLanguage = useSelector((state: MainState) => state.language.defaultLanguage);
  const selectedLanguages = useSelector((state: MainState) => state.language.selectedLanguageList);
  const form = useSelector((state: MainState) => state.form);

  const { multiselection } = question;

  const handleCascadingModalClose = () => {
    setIsCascadingModalOpen(false);
  };

  const _createGroup = () => {
    dispatch(createGroup({ languageCode: defaultLanguage.code }));
  };

  const onChange = (key: any, value: any) => {
    dispatch(setForm({ [key]: value }));
  };

  const save = async (e: any) => {
    setLoading(true);
    const urlParams = queryString.parse(window.location.search);
    const surveyId = urlParams.surveyId;

    const validStatus = checkFormValidation(form);
    if (!validStatus.valid && validStatus.msg) {
      toast.error(validStatus.msg);
      setLoading(false);
      return;
    }

    const labelErrors = validateQuestionLabels(question.questions);
    if (labelErrors.length > 0) {
      labelErrors.forEach((error) => toast.error(error.msg));
      setLoading(false);
      return;
    }

    const payload: ISubmitForm = {
      idString: form.formName,
      title: form.formTitle,
      formStyle: form.formStyle,
      metaData: form.metaData,
      formJson: question.questions,
      selectedLanguages: selectedLanguages.map((item) => item.id),
      ...(surveyId && { id: surveyId as string }), // Conditionally add id if surveyId exists
    };

    try {
      const response = await axiosServices.post(`${saveURL}`, payload);
      if (response.status === 200) {
        const redirectToUrl = `${process.env.REACT_APP_REDIRECT_URL}/forms`;
        toast.success('Form saved successfully !!!');
        setTimeout(() => {
          setLoading(false);
          window.location.href = redirectToUrl;
        }, 3000);
      } else {
        setLoading(false);
      }
    } catch (ex) {
      setLoading(false);
      toast.error('Cannot save the form');
    }
  };

  return (
    <Box marginBottom={10}>
      <Box
        className={classes.formHeader}
        sx={{
          borderRadius: '5px 5px 0 0',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          zIndex: 1000,
        }}
      >
        <Box>
          <Box className={classes.formName}>
            <input
              className={classes.formTextEditable}
              placeholder="Form Name"
              type="text"
              value={form.formName}
              // onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange('formName', e.target.value)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                // Use the helper function to process the input value
                const formattedValue = formatFormName(e.target.value);

                // Update the form state with the formatted value
                onChange('formName', formattedValue);
              }}
            />
          </Box>
          <Box className={classes.formTitle}>
            <input
              className={classes.formTextEditable}
              placeholder="Form Title"
              type="text"
              value={form.formTitle}
              onChange={(e: any) => onChange('formTitle', e.target.value)}
            />
          </Box>
        </Box>
        <Box>
          <Button disabled={loading} variant={'contained'} onClick={save}>
            Save
          </Button>
          {/* <IconButton color="error">
            <ClearTwoToneIcon />
          </IconButton> */}
        </Box>
      </Box>

      <Box
        className={classes.action}
        style={{ borderRadius: '5px 5px 0 0', position: 'fixed', top: '63px', left: 0, width: '100%', zIndex: 999 }}
      >
        <Box>
          <Tooltip title="Preview" placement="bottom-end" arrow>
            <IconButton>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="icon icon-tabler icons-tabler-outline icon-tabler-eye"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
              </svg>
            </IconButton>
          </Tooltip>
          {/* <IconButton>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="icon icon-tabler icons-tabler-outline icon-tabler-arrows-sort"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M3 9l4 -4l4 4m-4 -4v14" />
              <path d="M21 15l-4 4l-4 -4m4 4v-14" />
            </svg>
          </IconButton> */}
          <Tooltip title="Group" placement="bottom-end" arrow>
            <span>
              <IconButton
                onClick={_createGroup}
                disabled={!multiselection.onGoing}
                style={{ backgroundColor: !multiselection.onGoing ? 'transparent' : 'lightblue' }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="icon icon-tabler icons-tabler-outline icon-tabler-folders"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M9 4h3l2 2h5a2 2 0 0 1 2 2v7a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2" />
                  <path d="M17 17v2a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2h2" />
                </svg>
              </IconButton>
            </span>
          </Tooltip>
          <IconButton
            onClick={() => {
              setIsCascadingModalOpen(true);
              // console.log(parseCascadingSelectData(data))
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="icon icon-tabler icons-tabler-outline icon-tabler-sitemap"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M3 15m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v2a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
              <path d="M15 15m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v2a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
              <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v2a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
              <path d="M6 15v-1a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v1" />
              <path d="M12 9l0 3" />
            </svg>
          </IconButton>
        </Box>
        <Box className={classes.layoutSettings}>
          <Stack direction={'row'} gap={1} alignItems={'center'}>
            {/* <Icons.Line /> */}
            <Button onClick={() => setOpenQuestionBank(true)}>Add from library</Button>
            <Icons.Line />
            <Button onClick={() => setOpen(true)}>Layout & settings</Button>
            <Icons.Line />
            <Button onClick={() => setOpenLanguageSelection(true)}>Select Language</Button>
          </Stack>
        </Box>
      </Box>
      <Modal
        title="Layout & Settings"
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={1000}
        footer={null}
      >
        <LayoutSettings close={() => setOpen(false)} />
      </Modal>
      <Modal
        title="Question Bank"
        centered
        open={openQuestionBank}
        onOk={() => setOpenQuestionBank(false)}
        onCancel={() => setOpenQuestionBank(false)}
        width={1000}
        footer={null}
      >
        <QuestionBank close={() => setOpenQuestionBank(false)} />
      </Modal>

      <Modal
        title="Question Bank"
        centered
        open={openLanguageSelection}
        onOk={() => setOpenLanguageSelection(false)}
        onCancel={() => setOpenLanguageSelection(false)}
        width={1000}
        footer={null}
      >
        <LanguageSelection close={() => setOpenLanguageSelection(false)} />
      </Modal>

      <Dialog
        // onBackdropClick={() => setIsModalOpen(true)}
        maxWidth="md"
        fullWidth
        disableEnforceFocus
        disableAutoFocus
        disableRestoreFocus
        onClose={(event, reason) => {
          if (reason && reason === 'backdropClick') return;
          handleCascadingModalClose();
        }}
        open={isCascadingModalOpen}
        sx={{ '& .MuiDialog-paper': { p: 0 } }}
      >
        {isCascadingModalOpen && (
          <Box sx={{ position: 'relative' }}>
            <Box
              sx={{
                position: 'sticky',
                top: 0,
                zIndex: 2,
                // backgroundColor: 'white'
              }}
            >
              <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <DialogTitle>{'Import Cascading Select Questions'}</DialogTitle>
                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      window.open(
                        'https://docs.google.com/spreadsheets/d/1C_uDOkjjbv5Kx3lyOY7ORwM-muW6BKVzdaPMB1X8-2A/edit?gid=0#gid=0',
                        '_blank',
                      )
                    }
                  >
                    Sample
                  </Button>
                  <Tooltip placement="top" title={'close'}>
                    <IconButton onClick={() => handleCascadingModalClose()} color="default" size="medium">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-x"
                        width="28"
                        height="28"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="#56565B"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M18 6l-12 12" />
                        <path d="M6 6l12 12" />
                      </svg>
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Stack>

              <Divider />
            </Box>
            <Box sx={{ p: 4 }}>
              <CascadingSelectQuestions onCancel={() => handleCascadingModalClose()} />
            </Box>
          </Box>
        )}
      </Dialog>
    </Box>
  );
}
