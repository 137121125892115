export const YES = 'yes';
export const NO = 'no';
export const CUSTOM_LOGIC = 'custom_logic';
export const QUESTION_OPTION = 'Question Option';
export const TRANSLATE = 'Translation';
export const SKIP_LOGIC = 'Skip Logic';
export const VALIDATION_CRITERIA = 'Validation Criteria';
export const RATING_OPTION = 'Rating Option';
export const CHOICE_OPTION = 'Choice';
export const RANKING_OPTION = 'Rank Option';
export const QUESTION_MATRIX_OPTION = 'Question Matrix Option';
export const RANGE_OPTION = 'Range Option';
export const appearanceOptions = [
  'minimal',
  'quick',
  'horizontal-compact',
  'horizontal',
  'likert',
  'compact',
  'quickcompact',
  'label',
  'list-nolabel',
  'other',
];
export const hxlTags = [
  'capacity',
  'activity',
  'adm1',
  'adm2',
  'adm3',
  'adm4',
  'adm5',
  'affected',
  'benificiary',
  'cause',
  'country',
  'crisis',
  'date',
  'description',
  'geo',
  'impact',
  'inneed',
  'loc',
  'meta',
  'operations',
  'org',
  'output',
  'population',
  'reached',
  'region',
  'sector',
  'severity',
  'subsector',
];

import {
  ACKNOWLEDGE,
  AREA,
  AUDIO,
  CALCULATE,
  DATE,
  DATE_TIME,
  DECIMAL,
  MULTIPLE_SELECT,
  PHOTO,
  SELECT_ONE,
  TEXT,
  BARCODE,
  NUMBER,
  FILE,
  NOTE,
  POINT,
  QUESTION_MATRIX,
  RANGE,
  RANKING,
  TIME,
  VIDEO,
  RATING,
  LINE,
} from '../QuesTypes/Constants';

export const getQuesConfigByType = (type: string): string[] => {
  switch (type) {
    case TEXT:
      return [QUESTION_OPTION, TRANSLATE, SKIP_LOGIC, VALIDATION_CRITERIA];

    case AREA:
      return [QUESTION_OPTION, TRANSLATE, SKIP_LOGIC, VALIDATION_CRITERIA];

    case AUDIO:
      return [QUESTION_OPTION, TRANSLATE, SKIP_LOGIC, VALIDATION_CRITERIA];

    case BARCODE:
      return [QUESTION_OPTION, TRANSLATE, SKIP_LOGIC, VALIDATION_CRITERIA];

    case DECIMAL:
      return [QUESTION_OPTION, TRANSLATE, SKIP_LOGIC, VALIDATION_CRITERIA];

    case DATE:
      return [QUESTION_OPTION, TRANSLATE, SKIP_LOGIC, VALIDATION_CRITERIA];

    case DATE_TIME:
      return [QUESTION_OPTION, TRANSLATE, SKIP_LOGIC, VALIDATION_CRITERIA];

    case TIME:
      return [QUESTION_OPTION, TRANSLATE, SKIP_LOGIC, VALIDATION_CRITERIA];

    case CALCULATE:
      return [QUESTION_OPTION, TRANSLATE, SKIP_LOGIC, VALIDATION_CRITERIA];

    case RATING:
      return [RATING_OPTION, TRANSLATE, QUESTION_OPTION, SKIP_LOGIC];

    case NOTE:
      return [QUESTION_OPTION, TRANSLATE, SKIP_LOGIC, VALIDATION_CRITERIA];

    case SELECT_ONE:
      return [QUESTION_OPTION, TRANSLATE, CHOICE_OPTION, SKIP_LOGIC, VALIDATION_CRITERIA];

    case MULTIPLE_SELECT:
      return [QUESTION_OPTION, TRANSLATE, CHOICE_OPTION, SKIP_LOGIC, VALIDATION_CRITERIA];

    case PHOTO:
      return [QUESTION_OPTION, TRANSLATE, SKIP_LOGIC, VALIDATION_CRITERIA];

    case FILE:
      return [QUESTION_OPTION, TRANSLATE, SKIP_LOGIC, VALIDATION_CRITERIA];

    case POINT:
      return [QUESTION_OPTION, TRANSLATE, SKIP_LOGIC, VALIDATION_CRITERIA];

    case ACKNOWLEDGE:
      return [QUESTION_OPTION, TRANSLATE, SKIP_LOGIC, VALIDATION_CRITERIA];

    case RANKING:
      return [QUESTION_OPTION, TRANSLATE, RANKING_OPTION, SKIP_LOGIC, VALIDATION_CRITERIA];

    case QUESTION_MATRIX:
      return [QUESTION_OPTION, TRANSLATE, QUESTION_MATRIX_OPTION, SKIP_LOGIC, VALIDATION_CRITERIA];

    case RANGE:
      return [QUESTION_OPTION, TRANSLATE, SKIP_LOGIC, RANGE_OPTION, VALIDATION_CRITERIA];

    case LINE:
      return [QUESTION_OPTION, TRANSLATE, SKIP_LOGIC, VALIDATION_CRITERIA];

    case NUMBER:
      return [QUESTION_OPTION, TRANSLATE, SKIP_LOGIC, VALIDATION_CRITERIA];

    default:
      return [QUESTION_OPTION, TRANSLATE, VALIDATION_CRITERIA];
  }
};
export default {};
