import { PlusOutlined } from '@ant-design/icons';
import { Box, Button } from '@material-ui/core';
import { Form, Input } from 'antd';
import React, { useLayoutEffect } from 'react';
import ConditionValidation from './ConditionValidation';
import ManuallyValidation from './ManuallyValidation';
import Style from './Style';
import { useSelector } from 'react-redux';
import { getQuestionByPath } from '../../../../helpers/utils';
import { IQuestion, MainState, ILanguage } from '../../../../types/state';

export default function RenderValidation(props: any) {
  const questions: IQuestion[] = useSelector((state: MainState) => state.question.questions);

  const defaultLanguage: ILanguage = useSelector((state: MainState) => state.language.defaultLanguage);
  const selectedQuestion: IQuestion = useSelector((state: MainState) => state.question.selectedQuestion);
  const currentQuestion = getQuestionByPath(questions, selectedQuestion.path);

  const [validationRender, setValidationRender] = React.useState('');
  const classes = Style();

  useLayoutEffect(() => {
    const {
      question: { validation, validationCode },
    } = props;

    let state = '';
    if (currentQuestion.manualValidation) state = '2';
    if (currentQuestion.validation.length) state = '1';
    setValidationRender(state);
  }, []);

  switch (validationRender) {
    case '1':
      return <ConditionValidation setValidationRender={setValidationRender} {...props} />;
    case '2':
      return <ManuallyValidation setValidationRender={setValidationRender} {...props} />;
    default:
      return (
        <Box className={classes.validation}>
          <Box className={classes.btnBox}>
            <Button
              className={classes.btnBlue}
              variant="outlined"
              onClick={() => {
                props.addValidation();
                setValidationRender('1');
              }}
            >
              <PlusOutlined /> <span style={{ paddingLeft: 5 }}>Add validation</span>
            </Button>
            <br />
            <br />
            <Button className={classes.btnOrange} variant="outlined" onClick={() => setValidationRender('2')}>
              ${} Manually enter your validation in xls form code
            </Button>
          </Box>
        </Box>
      );
  }
}
