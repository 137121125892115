import { CloseOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Box, IconButton } from '@material-ui/core';
import { Button, Form, Input, Select, Typography } from 'antd';
import { Grid, TextField, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import React, { useLayoutEffect } from 'react';
import ContactStyle from './Style';
import { getLocalizedText, getQuestionByPath } from '../../../../helpers/utils';
import {
  addValidationCondition,
  clearValidation,
  removeValidationCondition,
  updateErrorMessage,
  updateValidationCondition,
  updateValidationConditionValue,
} from '../../../../redux/QuestionSlice';
import { ICondition, IQuestion, IValidation, MainState } from '../../../../types/state';
import { v4 as uuidv4 } from 'uuid';

type ConditionValidationPropsType = {
  setValidationRender: (value: string) => void;
};

export default function ConditionValidation({ setValidationRender }: ConditionValidationPropsType) {
  const classes = ContactStyle();
  const defaultLanguage = useSelector((state: MainState) => state.language.defaultLanguage);
  const selectedLanguage = useSelector((state: MainState) => state.language.selectedLanguageList);
  const selectedQuestion: IQuestion = useSelector((state: MainState) => state.question.selectedQuestion);
  const questions: IQuestion[] = useSelector((state: MainState) => state.question.questions);

  // const mainQuestion = questions.find((question) => question.id === questionItem.id);
  const currentQuestion = getQuestionByPath(questions, selectedQuestion.path);
  const selectedLanguages = useSelector((state: MainState) => state.language.selectedLanguageList);
  const dispatch = useDispatch();

  const getValidationOptions = (type: string) => {
    switch (type) {
      case 'text':
        return (
          <>
            <Select.Option value="equal">Equal (=)</Select.Option>
            <Select.Option value="not_equal">Not Equal (!=)</Select.Option>
          </>
        );
      case 'integer':
      case 'decimal':
        return (
          <>
            <Select.Option value="equal">Equal (=)</Select.Option>
            <Select.Option value="not_equal">Not Equal (!=)</Select.Option>
            <Select.Option value="less_than">Less Than (&lt;)</Select.Option>
            <Select.Option value="greater_than">Greater Than (&gt;)</Select.Option>
            <Select.Option value="less_than_or_equal">Less Than or Equal (&lt;=)</Select.Option>
            <Select.Option value="greater_than_or_equal">Greater Than or Equal (&gt;=)</Select.Option>
          </>
        );
      case 'date':
      case 'datetime':
        return (
          <>
            <Select.Option value="equal">Equal (=)</Select.Option>
            <Select.Option value="not_equal">Not Equal (!=)</Select.Option>
            <Select.Option value="less_than">Before (&lt;)</Select.Option>
            <Select.Option value="greater_than">After (&gt;)</Select.Option>
            <Select.Option value="less_than_or_equal">On or Before (&lt;=)</Select.Option>
            <Select.Option value="greater_than_or_equal">On or After (&gt;=)</Select.Option>
          </>
        );
      case 'boolean':
      case 'select_one':
      case 'select_multiple':
        return (
          <>
            <Select.Option value="equal">Equal (=)</Select.Option>
            <Select.Option value="not_equal">Not Equal (!=)</Select.Option>
          </>
        );
      default:
        return (
          <>
            <Select.Option value="equal">Equal (=)</Select.Option>
            <Select.Option value="not_equal">Not Equal (!=)</Select.Option>
          </>
        );
    }
  };

  const handleAddCondition = () => {
    const condition: IValidation = {
      id: uuidv4(),
      condition: '',
      value: '',
    };
    dispatch(addValidationCondition({ path: currentQuestion.path, condition }));
  };

  return (
    <>
      <Box className={classes.closeOutlinedIcon}>
        <IconButton
          onClick={() => {
            dispatch(clearValidation({ path: currentQuestion.path }));
            setValidationRender('');
          }}
        >
          <CloseOutlined className={classes.icon} />
        </IconButton>
      </Box>

      <Box className={classes.conditionValidationText}>
        <p>This question will be valid only displayed if the following conditions apply </p>
      </Box>
      {currentQuestion.validation &&
        currentQuestion.validation.map((validation: IValidation, index: number) => (
          <Box key={index} margin={3}>
            <Box className={classes.conditionValidationForm}>
              <IconButton
                className={classes.minusOutlinedIcon}
                onClick={() =>
                  dispatch(removeValidationCondition({ path: currentQuestion.path, conditionId: validation.id }))
                }
              >
                <MinusOutlined className={classes.icon} />
              </IconButton>
              <Box margin={5}>
                <Box className={classes.conditionValidationFields}>
                  <Form.Item
                    style={{ width: '100%' }}
                    label="The question’s response has to be:"
                    rules={[{ required: true }]}
                  >
                    <Select
                      value={validation.condition}
                      onChange={(value) =>
                        dispatch(
                          updateValidationCondition({
                            path: currentQuestion.path,
                            conditionId: validation.id,
                            conditionText: value,
                          }),
                        )
                      }
                      allowClear
                    >
                      {getValidationOptions(currentQuestion.type)}
                    </Select>
                  </Form.Item>
                  <Form.Item style={{ width: '100%' }} label="Value" rules={[{ required: true }]}>
                    <Input
                      value={validation.value}
                      onChange={(e) =>
                        dispatch(
                          updateValidationConditionValue({
                            path: currentQuestion.path,
                            conditionId: validation.id,
                            value: e.target.value,
                          }),
                        )
                      }
                    />
                  </Form.Item>
                </Box>
                {/* <Box className={classes.conditionValidationFields}>
                <Form.Item style={{ width: '100%' }} label="Error Message:" rules={[{ required: true }]}>
                  <Input
                    value={data.errorMsg}
                    onChange={(e: any) => props.onChange(data, index, e.target.value, 'errorMsg')}
                  />
                </Form.Item>
              </Box> */}
              </Box>
            </Box>
          </Box>
        ))}
      <Grid container>
        <Grid container item justifyContent="right">
          <Button type="dashed" icon={<PlusOutlined />} size="large" onClick={handleAddCondition}>
            Add Validation
          </Button>
        </Grid>

        {/* {currentQuestion.validationCriteria && (
          <Grid container item justifyContent="center" xs={12}>
            <TextField
              style={{ width: '100%' }}
              sx={{ mx: 20 }}
              select
              id="standard-basic"
              label="Select a criteria"
              variant="standard"
              value={props.question.validationCriteria}
              onChange={(e: any) => {
                props.onEvent('validationCriteria', e.target.value);
              }}
            >
              <MenuItem value="all">Question should match all of these validation criteria</MenuItem>
              <MenuItem value="any">Question should match any of these validation criteria</MenuItem>
            </TextField>
          </Grid>
        )} */}
        <Box className={classes.conditionValidationFields} style={{ marginTop: '30px' }}>
          <Form.Item
            style={{ width: '100%' }}
            label={`Error Message (${defaultLanguage.code}) - default:`}
            rules={[{ required: true }]}
          >
            <Input
              value={getLocalizedText(currentQuestion.errorMsg, defaultLanguage.code)}
              onChange={(e) =>
                dispatch(
                  updateErrorMessage({
                    path: selectedQuestion.path,
                    languageCode: defaultLanguage.code,
                    label: e.target.value,
                  }),
                )
              }
            />
          </Form.Item>
        </Box>

        {selectedLanguages &&
          selectedLanguages.map((language) => (
            <Box className={classes.conditionValidationFields} style={{ marginTop: '30px' }} key={language.code}>
              <Form.Item
                style={{ width: '100%' }}
                label={`Error Message (${language.code}):`}
                rules={[{ required: true }]}
              >
                <Input
                  value={getLocalizedText(currentQuestion.errorMsg, language.code)}
                  onChange={(e) =>
                    dispatch(
                      updateErrorMessage({
                        path: selectedQuestion.path,
                        languageCode: language.code,
                        label: e.target.value,
                      }),
                    )
                  }
                />
              </Form.Item>
            </Box>
          ))}
      </Grid>
    </>
  );
}
