import React, { useEffect, useState } from 'react';
import QuestionOption from './Options';
import { Grid } from '@material-ui/core';
import SkipLogic from './SkipLogic';
import ValidationCriteria from './ValidationCriteria';
import { useDispatch, useSelector } from 'react-redux';
import { closeSettingsTab, update } from '../../../redux/QuestionSlice';
// import Style from './Style';
import { isValid } from './settingsService';
import { Button, Tabs } from 'antd';
import Rating from './Options/Rating';
import { MULTIPLE_SELECT, SELECT_ONE } from '../QuesTypes/Constants';
import Translate from './Options/Translate/';

import {
  CHOICE_OPTION,
  getQuesConfigByType,
  QUESTION_MATRIX_OPTION,
  QUESTION_OPTION,
  RANGE_OPTION,
  RANKING_OPTION,
  RATING_OPTION,
  SKIP_LOGIC,
  TRANSLATE,
  VALIDATION_CRITERIA,
} from './constants';
import Choice from './Options/Choice';
import QuestionMatrix from './Options/QuestionMatrix';
import Range from './Options/Range';

import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import RankingOption from './Options/Ranking';
// import Options from './Options';
// import { O_TRUNC } from 'constants';
import toast from 'react-hot-toast';
import { Box, Collapse } from '@mui/material';
import SettingsLayout from '../../../layouts/SettingsLayout';
import { IQuestion, MainState } from '../../../types/state';

type QuestionConfigProps = {
  questionItem: IQuestion;
};

export default function QuestionConfig({ questionItem }: QuestionConfigProps) {
  const dispatch = useDispatch();
  const showSettingsTab = useSelector((state: MainState) => state.question.showSettingsTab);
  // const classes = Style();
  const selectedQuestion = useSelector((state: MainState) => state.question.selectedQuestion);
  const questions = useSelector((state: MainState) => state.question.questions);
  // const state = useSelector((state: any) => state.question);
  const [previousQuestion, setPreviousQuestion] = useState<any>(null);
  // const [msg, setMsg] = useState<any>({ severity: 'success', txt: '', show: false });
  const [question, setQuestion] = useState<IQuestion>(selectedQuestion);
  // console.log('questionn', question);
  /**
   * ------------------------------------------------------------------------------------------------------------------------
   * ----these 2 condition will apply if user forget to click the save button to save the configurtation.--------------------
   */
  /** condition 1 */
  if (
    previousQuestion !== null &&
    question.type !== '' &&
    selectedQuestion.type !== '' &&
    previousQuestion.path !== selectedQuestion.path
  ) {
    setPreviousQuestion(selectedQuestion);
    dispatch(update(question));
  }

  /** condition 2 */
  if (previousQuestion === null && selectedQuestion.type !== '') {
    setPreviousQuestion(selectedQuestion);
  }
  /**
   * ------------------------------------------------------------------------------------------------------------------------
   * ------------------------------------------------------------------------------------------------------------------------
   */

  useEffect(() => {
    setQuestion(selectedQuestion);
  }, [selectedQuestion]);

  // const cancel = () => dispatch(closeSettingsTab());

  const onEvent = (key: string, value: any) => {
    setQuestion((prevState: any) => ({
      ...prevState,
      [`${key}`]: value,
    }));
  };

  const configuration = (config: string) => {
    switch (config) {
      case QUESTION_OPTION:
        return <QuestionOption question={questionItem} />;

      case SKIP_LOGIC:
        return <SkipLogic question={question} />;

      case VALIDATION_CRITERIA:
        return <ValidationCriteria question={question} onChange={(ques: any) => setQuestion(ques)} onEvent={onEvent} />;

      case RATING_OPTION:
        return <Rating />;

      case CHOICE_OPTION:
        return <Choice question={question} />;

      case RANKING_OPTION:
        return <RankingOption />;

      case QUESTION_MATRIX_OPTION:
        return <QuestionMatrix />;

      case RANGE_OPTION:
        return <Range />;

      case TRANSLATE:
        return <Translate question={question} onChange={(ques: any) => setQuestion(ques)} />;

      default:
        return null;
    }
  };

  type IQuestionConfig = {
    name: string;
    element: React.ReactNode | null;
  };

  const questionConfigs = (): IQuestionConfig[] => {
    return getQuesConfigByType(question.type).map((name: string) => ({
      name,
      element: configuration(name),
    }));
  };

  return (
    <>
      <Collapse in={showSettingsTab}>
        <Box className="no-drag">
          <Tabs
            tabPosition="left"
            items={questionConfigs().map((elm: IQuestionConfig, i: number) => {
              const id = String(i + 1);
              return {
                label: elm.name,
                key: id,
                children: <SettingsLayout>{elm.element}</SettingsLayout>,
              };
            })}
            style={{ backgroundColor: 'white' }}
          />
          <Grid container justifyContent="center" style={{ padding: 30 }}>
            {/* <Button icon={<CloseOutlined />} danger onClick={cancel} style={{ marginRight: 20 }}>
              {' '}
              Cancel
            </Button> */}
            {/* <Button icon={<SaveOutlined />} type="primary" onClick={updateQuestion}>
              {' '}
              Submit
            </Button> */}
          </Grid>
        </Box>
      </Collapse>
    </>
  );
}
