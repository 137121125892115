import _ from 'lodash';
import { questionDefaultValue } from '../features/FormDesigner/Questions/Constants';
import { v4 as uuidv4 } from 'uuid';
import {
  ICondition,
  ILogic,
  IMatrixChoice,
  IMatrixColumn,
  IMatrixRow,
  IOption,
  IQuestion,
  IQuestionData,
  IRankItem,
  IRatingColumn,
  IRatingRow,
  IValidation,
} from '../types/state';
import { IAction } from '../types/common';
import {
  generateUniqueId,
  getLocalizedTextByObject,
  getLableByObject,
  getQuestionByPath,
  hasConflictingSelection,
  isNameUnique,
  updateQuestionPathsRearrange,
  findDependentQuestion,
} from '../helpers/utils';
import toast from 'react-hot-toast';

/**
 *  Common services that used in both question and group.
 */
export const travelToNode = (questionList: any, path: string) => {
  const indexes = path.split(',');
  for (let i = 0; i < indexes.length - 1; i++) {
    questionList = questionList[parseInt(indexes[i])].questions;
  }
  return questionList;
};

// export const travelToNode = (questionList: any, path: string) => {
//   const indexes = path.split(',').map(Number);

//   for (let i = 0; i < indexes.length - 1; i++) {
//     const index = indexes[i];

//     if (!questionList || !Array.isArray(questionList) || !questionList[index]) {
//       console.error(`Invalid path at index ${index}. Current question list:`, questionList);
//       return null; // Safely exit if path is invalid
//     }

//     // questionList = questionList[index].questions || null; // Move to the next level or return null if not present
//   }

//   console.log('Final node:', questionList);
//   return questionList;
// };

export const getNodeIndex = (data: any) => {
  if (data === '') return -1;
  return parseInt(data.split(',').pop());
};

/**
 *  Question Reducers
 */
const add = (state: IQuestionData, action: IAction<IQuestion>) => {
  const questions: IQuestion[] = travelToNode(state.questions, action.payload.path);
  const questionIndex = getNodeIndex(action.payload.path);

  action.payload.id = uuidv4();

  if (!isNameUnique(action.payload.name, undefined, state.questions)) {
    action.payload.name = `${action.payload.name}_${generateUniqueId(4)}`;
  }

  questions.splice(questionIndex + 1, 0, action.payload);
  state.selectedQuestion = action.payload;
  state.showSettingsTab = false;
  state.questions = updateQuestionPathsRearrange(state.questions);
  // return state;
};

const rearrange = (state: IQuestionData, action: any) => {
  // state.questions = _.cloneDeep(action.payload);
  const updatedQuestions = _.cloneDeep(action.payload);

  // Recursively update paths for all questions and nested groups
  state.questions = updateQuestionPathsRearrange(updatedQuestions);
  // return state;
};

const selectQuestion = (state: IQuestionData, action: any) => {
  state.selectedQuestion = _.cloneDeep(action.payload);
  // state.showSettingsTab = !state.showSettingsTab;
  state.multiselection.onGoing = false;
  state.multiselection.pathList = [];
  // return state;
};

const removeSelection = (state: IQuestionData) => {
  state.selectedQuestion = questionDefaultValue;
  state.multiselection.onGoing = false;
  state.multiselection.pathList = [];
};

const update = (state: IQuestionData, action: any) => {
  const questions = travelToNode(state.questions, action.payload.path);
  const questionIndex = getNodeIndex(action.payload.path);
  questions.splice(questionIndex, 1, action.payload);
};

const deleteQuestion = (state: IQuestionData, action: any) => {
  const questions = travelToNode(state.questions, action.payload.path);
  const questionIndex = getNodeIndex(action.payload.path);
  questions.splice(questionIndex, 1);
};

const toggleSettingsTab = (state: IQuestionData) => {
  state.showSettingsTab = !state.showSettingsTab;
};

const closeSettingsTab = (state: IQuestionData) => {
  state.showSettingsTab = false;
  state.selectedQuestion = questionDefaultValue;
};

// const addToMultiSelection = (state: any, action: IAction) => {
//   state.multiselection.onGoing = true;
//   state.showSettingsTab = false;
//   state.selectedQuestion = questionDefaultValue;
//   const pathIndex: number = state.multiselection.pathList.findIndex((path: string) => path === action.payload);

//   if (pathIndex !== -1) {
//     state.multiselection.pathList.splice(pathIndex, 1);
//   } else {
//     state.multiselection.pathList.push(action.payload);
//   }
// };

const addToMultiSelection = (state: IQuestionData, action: IAction) => {
  state.multiselection.onGoing = true; // Indicates that multi-selection is in progress
  state.showSettingsTab = false; // Close settings when multi-selecting

  // state.selectedQuestion = questionDefaultValue;

  const path = action.payload;
  const pathIndex = state.multiselection.pathList.findIndex((p: string) => p === path);

  // Use the helper function to check for conflicting selections
  if (state.multiselection?.pathList?.length && hasConflictingSelection(path, state.multiselection.pathList)) {
    toast.error('Can not add outside group');
    // Prevent adding the path if a conflict is detected
    return;
  }

  if (pathIndex !== -1) {
    // If the path is already selected, remove it (deselect)
    state.multiselection.pathList.splice(pathIndex, 1);
  } else {
    // Otherwise, add the path to the selection
    state.multiselection.pathList.push(path);
  }

  // This is given otherwise first one is selected already
  state.selectedQuestion = questionDefaultValue;
};

const stopMultiSelection = (state: IQuestionData) => {
  state.multiselection.onGoing = false;
  state.multiselection.pathList = [];
};

const setQuestions = (state: IQuestionData, action: IAction<{ questions: IQuestion[] }>) => {
  state.questions = action.payload.questions;
};

const updateQuestionName = (state: IQuestionData, action: IAction<{ path: string; name: string }>) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question) {
    const dependencyQuestion = findDependentQuestion(question.id, state.questions);
    if (dependencyQuestion) {
      toast.error(`Can not change name. It depends on ${dependencyQuestion}`);
    } else {
      question.name = action.payload.name;
    }
  }
};

const updateQuestionLabel = (
  state: IQuestionData,
  action: IAction<{ path: string; languageCode: string; label: string }>,
) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question) {
    const label = getLableByObject(question.label, action.payload.languageCode);

    const updatedLabel = {
      ...label,
      [action.payload.languageCode]: action.payload.label,
    };
    question.label = updatedLabel;
  }
};

const updateQuestionHint = (
  state: IQuestionData,
  action: IAction<{ path: string; languageCode: string; hint: string }>,
) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question) {
    const hint = getLocalizedTextByObject(question.hint, action.payload.languageCode);

    const updatedHint = {
      ...hint,
      [action.payload.languageCode]: action.payload.hint,
    };
    question.hint = updatedHint;
  }
};

const updateQuestionMandatoryResponse = (
  state: IQuestionData,
  action: IAction<{ path: string; mandatoryResponse: string }>,
) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question) {
    question.mandatory = action.payload.mandatoryResponse;
  }
};

const updateQuestionDefaultResponse = (
  state: IQuestionData,
  action: IAction<{ path: string; defaultResponse: string }>,
) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question) {
    question.defaultResponse = action.payload.defaultResponse;
  }
};

const updateQuestionApperance = (state: IQuestionData, action: IAction<{ path: string; appearance: string }>) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question) {
    question.appearance = action.payload.appearance;
  }
};

const updateQuestionHXLTag = (state: IQuestionData, action: IAction<{ path: string; tag: string }>) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question) {
    question.tag = action.payload.tag;
  }
};

const updateQuestionHXLTagValue = (state: IQuestionData, action: IAction<{ path: string; value: string }>) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question) {
    question.tagValue = action.payload.value;
  }
};

const updateQuestionParameter = (state: IQuestionData, action: IAction<{ path: string; parameter: boolean }>) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question) {
    question.parameters = action.payload.parameter;
  }
};

const updateQuestionSeed = (state: IQuestionData, action: IAction<{ path: string; seed: string }>) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question) {
    question.seed = action.payload.seed;
  }
};

const updateQuestionListName = (state: IQuestionData, action: IAction<{ path: string; listName: string }>) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question) {
    question.list_name = action.payload.listName;
  }
};

// Skip Logic
const updateQuestionDefaultLogic = (state: IQuestionData, action: IAction<{ path: string; logic: ILogic }>) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question) {
    question.logicList = [...question.logicList, { ...action.payload.logic, id: uuidv4() }];
  }
};

const removeAllSkipLogic = (state: IQuestionData, action: IAction<{ path: string }>) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question) {
    question.logicList = [];
  }
};

const removeSkipLogic = (state: IQuestionData, action: IAction<{ path: string; logicId: string }>) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question) {
    question.logicList = question.logicList.filter((logic: ILogic) => logic.id !== action.payload.logicId);
  }
};

const addSkipLogic = (state: IQuestionData, action: IAction<{ path: string; logic: ILogic }>) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question) {
    question.logicList = [...question.logicList, { ...action.payload.logic, id: uuidv4() }];
  }
};

const updateSkipLogic = (state: IQuestionData, action: IAction<{ path: string; logic: ILogic }>) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question) {
    question.logicList = [...question.logicList, action.payload.logic];
  }
};

const updateSkipLogicName = (
  state: IQuestionData,
  action: IAction<{ path: string; logicId: string; questionName: string; question: IQuestion }>,
) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question) {
    const logic = question.logicList.find((logic) => logic.id === action.payload.logicId);
    if (logic) {
      logic.questionName = action.payload.questionName;
      logic.question = action.payload.question;
    }
  }
};

const updateSkipLogicCondition = (
  state: IQuestionData,
  action: IAction<{ path: string; logicId: string; condition: ICondition }>,
) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question) {
    const logic = question.logicList.find((logic) => logic.id === action.payload.logicId);
    if (logic) {
      logic.condition = action.payload.condition;
    }
  }
};

const updateSkipLogicValue = (
  state: IQuestionData,
  action: IAction<{ path: string; logicId: string; value: string }>,
) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question) {
    const logic = question.logicList.find((logic) => logic.id === action.payload.logicId);
    if (logic) {
      logic.value = action.payload.value;
    }
  }
};

const addChoiceOption = (state: IQuestionData, action: IAction<{ path: string; option: IOption }>) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question && question.choice) {
    // Add the new option to the existing options array
    question.choice.options = [...(question.choice.options || []), action.payload.option];
  }
};

const removeChoiceOption = (state: IQuestionData, action: IAction<{ path: string; optionId: string }>) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question && question.choice) {
    // Filter out the option with the specified uuid
    question.choice.options = question.choice.options.filter((opt: IOption) => opt.uuid !== action.payload.optionId);
  }
};

const updateChoiceOptionName = (
  state: IQuestionData,
  action: IAction<{ path: string; optionId: string; optionName: string; languageCode: string }>,
) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question && question.choice) {
    const optionToUpdate = question.choice.options.find((opt: any) => opt.uuid === action.payload.optionId);

    if (optionToUpdate) {
      optionToUpdate.option = {
        ...optionToUpdate.option,
        [action.payload.languageCode]: action.payload.optionName,
      };
    }
  }
};

const updateChoiceOptionValue = (
  state: IQuestionData,
  action: IAction<{ path: string; optionId: string; value: number | string }>,
) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question && question.choice) {
    const optionToUpdate = question.choice.options.find((opt: any) => opt.uuid === action.payload.optionId);

    if (optionToUpdate) {
      optionToUpdate.value = action.payload.value;
    }
  }
};

const updateQuestionChoiceOption = (state: IQuestionData, action: IAction<{ path: string; newOption: any }>) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question && question.choice && question.choice.options) {
    // Find the index of the option using the uuid
    const optionIndex = question.choice.options.findIndex(
      (option: any) => option.uuid === action.payload.newOption.uuid,
    );

    // If the option with the provided uuid is found, update its value
    if (optionIndex !== -1) {
      question.choice.options[optionIndex] = action.payload.newOption;
    }
  }
};

const updateDependencyQuestion = (state: any, action: IAction<{ path: string; newQuestion: IQuestion | null }>) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question) {
    if (action.payload.newQuestion !== null) question.dependencyQuestion = action.payload.newQuestion;
    else delete question.dependencyQuestion;
  }
};

const updateManualSkipLogic = (state: IQuestionData, action: IAction<{ path: string; value: string }>) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question) {
    question.manualSkipLogic = action.payload.value;
  }
};

// Validation

const addValidationCondition = (state: IQuestionData, action: IAction<{ path: string; condition: IValidation }>) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question) {
    question.validation = [...(question.validation || []), action.payload.condition];
  }
};

const removeValidationCondition = (state: IQuestionData, action: IAction<{ path: string; conditionId: string }>) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question && question.validation) {
    question.validation = question.validation.filter((condition) => condition.id !== action.payload.conditionId);
  }
};

const updateValidationCondition = (
  state: IQuestionData,
  action: IAction<{ path: string; conditionId: string; conditionText: string }>,
) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question && question.validation) {
    const condition = question.validation.find((condition) => condition.id === action.payload.conditionId);
    if (condition) {
      condition.condition = action.payload.conditionText;
    }
  }
};

const updateValidationConditionValue = (
  state: IQuestionData,
  action: IAction<{ path: string; conditionId: string; value: string }>,
) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question && question.validation) {
    const condition = question.validation.find((condition) => condition.id === action.payload.conditionId);
    if (condition) {
      condition.value = action.payload.value;
    }
  }
};

const clearValidation = (state: IQuestionData, action: IAction<{ path: string }>) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question) {
    question.validation = [];
  }
};

const updateManualValidation = (state: IQuestionData, action: IAction<{ path: string; manualValidation: string }>) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question) {
    question.manualValidation = action.payload.manualValidation;
  }
};

const updateErrorMessage = (
  state: IQuestionData,
  action: IAction<{ path: string; languageCode: string; label: string }>,
) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question) {
    const errorMessage = getLocalizedTextByObject(question.errorMsg, action.payload.languageCode);

    const updatedMessage = {
      ...errorMessage,
      [action.payload.languageCode]: action.payload.label,
    };
    question.errorMsg = updatedMessage;
  }
};

// Rating
const addRatingColumn = (state: IQuestionData, action: IAction<{ path: string; column: IRatingColumn }>) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question && question.ratings) {
    question.ratings.columns = [...(question.ratings.columns || []), action.payload.column];
  }
};

const removeRatingColumn = (state: IQuestionData, action: IAction<{ path: string; columnId: string }>) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question && question.ratings) {
    question.ratings.columns = question.ratings.columns.filter((column) => column.id !== action.payload.columnId);
  }
};

const updateRatingColumnTitle = (
  state: IQuestionData,
  action: IAction<{ path: string; columnId: string; newTitle: string }>,
) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question && question.ratings) {
    const column = question.ratings.columns.find((column) => column.id === action.payload.columnId);
    if (column) {
      // Hardcoded language for now
      const title = getLableByObject(action.payload.newTitle, 'EN');
      column.title = title;
    }
  }
};

const updateRatingColumnSubtitle = (
  state: IQuestionData,
  action: IAction<{ path: string; columnId: string; newSubTitle: string }>,
) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question && question.ratings) {
    const column = question.ratings.columns.find((column) => column.id === action.payload.columnId);
    if (column) {
      // Hardcoded language for now
      const subTitle = getLableByObject(action.payload.newSubTitle, 'EN');
      column.subTitle = subTitle;
    }
  }
};

const updateRatingRowTitle = (
  state: IQuestionData,
  action: IAction<{ path: string; rowId: string; newTitle: string }>,
) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question && question.ratings) {
    const row = question.ratings.rows.find((row) => row.id === action.payload.rowId);
    if (row) {
      // Hardcoded language for now
      const title = getLableByObject(action.payload.newTitle, 'EN');
      row.title = title;
    }
  }
};

const updateRatingRowSubtitle = (
  state: IQuestionData,
  action: IAction<{ path: string; rowId: string; newSubTitle: string }>,
) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question && question.ratings) {
    const row = question.ratings.rows.find((row) => row.id === action.payload.rowId);
    if (row) {
      // Hardcoded language for now
      const subTitle = getLableByObject(action.payload.newSubTitle, 'EN');
      row.subTitle = subTitle;
    }
  }
};

const addRatingRow = (state: IQuestionData, action: IAction<{ path: string; row: IRatingRow }>) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question && question.ratings) {
    question.ratings.rows = [...(question.ratings.rows || []), action.payload.row];
  }
};

const removeRatingRow = (state: IQuestionData, action: IAction<{ path: string; rowId: string }>) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question && question.ratings) {
    question.ratings.rows = question.ratings.rows.filter((row) => row.id !== action.payload.rowId);
  }
};

// Question matrix

const addMatrixRow = (state: IQuestionData, action: IAction<{ path: string; row: IMatrixRow }>) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question && question.matrix) {
    question.matrix.rows = [...(question.matrix.rows || []), action.payload.row];
  }
};

const removeMatrixRow = (state: IQuestionData, action: IAction<{ path: string; rowId: string }>) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question && question.matrix) {
    question.matrix.rows = question.matrix.rows.filter((row: IMatrixRow) => row.id !== action.payload.rowId);
  }
};

const updateMatrixRowLabel = (
  state: IQuestionData,
  action: IAction<{ path: string; rowId: string; newLabel: string }>,
) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question && question.matrix) {
    const rowToUpdate = question.matrix.rows.find((row: IMatrixRow) => row.id === action.payload.rowId);

    if (rowToUpdate) {
      rowToUpdate.label = action.payload.newLabel;
    }
  }
};

const updateMatrixRowPrefix = (
  state: IQuestionData,
  action: IAction<{ path: string; rowId: string; prefix: string }>,
) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question && question.matrix) {
    const rowToUpdate = question.matrix.rows.find((row: IMatrixRow) => row.id === action.payload.rowId);

    if (rowToUpdate) {
      rowToUpdate.dataColumnPrefix = action.payload.prefix;
    }
  }
};

const addMatrixColumn = (state: IQuestionData, action: IAction<{ path: string; column: IMatrixColumn }>) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question && question.matrix) {
    question.matrix.columns = [...(question.matrix.columns || []), action.payload.column];
  }
};

const updateMatrixLabel = (
  state: IQuestionData,
  action: IAction<{ path: string; columnId: string; newLabel: string }>,
) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question && question.matrix) {
    const column = question.matrix.columns.find((col) => col.id === action.payload.columnId);

    if (column) {
      column.label = action.payload.newLabel;
    }
  }
};

const updateMatrixDataColumnSuffix = (
  state: IQuestionData,
  action: IAction<{ path: string; columnId: string; suffix: string }>,
) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question && question.matrix) {
    const column = question.matrix.columns.find((col) => col.id === action.payload.columnId);

    if (column) {
      column.dataColumnSuffix = action.payload.suffix;
    }
  }
};

const removeMatrixColumn = (state: IQuestionData, action: IAction<{ path: string; columnId: string }>) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question && question.matrix) {
    question.matrix.columns = question.matrix.columns.filter(
      (col: IMatrixColumn) => col.id !== action.payload.columnId,
    );
  }
};

const updateMatrixColumnLabel = (
  state: IQuestionData,
  action: IAction<{ path: string; columnId: string; newLabel: string }>,
) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question && question.matrix) {
    const columnToUpdate = question.matrix.columns.find((col: IMatrixColumn) => col.id === action.payload.columnId);

    if (columnToUpdate) {
      columnToUpdate.label = action.payload.newLabel;
    }
  }
};

const updateMatrixColumnResponseType = (
  state: IQuestionData,
  action: IAction<{ path: string; columnId: string; type: string }>,
) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question && question.matrix) {
    const columnToUpdate = question.matrix.columns.find((col: IMatrixColumn) => col.id === action.payload.columnId);

    if (columnToUpdate) {
      columnToUpdate.responseType = action.payload.type;
    }
  }
};

const updateColumnExpand = (state: IQuestionData, action: IAction<{ path: string; columnId: string }>) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question && question.matrix) {
    question.matrix.columns = question.matrix.columns.filter(
      (col: IMatrixColumn) => col.id !== action.payload.columnId,
    );
  }
};

const addMatrixChoiceOption = (
  state: IQuestionData,
  action: IAction<{ path: string; columnId: string; option: IMatrixChoice }>,
) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question && question.matrix) {
    const columnToUpdate = question.matrix.columns.find((col: IMatrixColumn) => col.id === action.payload.columnId);

    if (columnToUpdate) {
      // Add the new option to the existing choices array
      columnToUpdate.choices = [...(columnToUpdate.choices || []), action.payload.option];
    }
  }
};
const removeMatrixChoiceOption = (
  state: IQuestionData,
  action: IAction<{ path: string; columnId: string; optionId: string }>,
) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question && question.matrix) {
    const columnToUpdate = question.matrix.columns.find((col: IMatrixColumn) => col.id === action.payload.columnId);

    if (columnToUpdate) {
      // Remove the option with the specified ID
      columnToUpdate.choices = columnToUpdate.choices?.filter((option) => option.id !== action.payload.optionId) || [];
    }
  }
};

const updateMatrixChoiceOptionLabel = (
  state: IQuestionData,
  action: IAction<{ path: string; columnId: string; optionId: string; newLabel: string }>,
) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question && question.matrix) {
    const columnToUpdate = question.matrix.columns.find((col: IMatrixColumn) => col.id === action.payload.columnId);

    if (columnToUpdate) {
      const optionToUpdate = columnToUpdate.choices?.find((option) => option.id === action.payload.optionId);

      if (optionToUpdate) {
        optionToUpdate.label = action.payload.newLabel;
      }
    }
  }
};

const updateMatrixChoiceOptionValue = (
  state: IQuestionData,
  action: IAction<{ path: string; columnId: string; optionId: string; newValue: string }>,
) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question && question.matrix) {
    const columnToUpdate = question.matrix.columns.find((col: IMatrixColumn) => col.id === action.payload.columnId);

    if (columnToUpdate) {
      const optionToUpdate = columnToUpdate.choices?.find((option) => option.id === action.payload.optionId);

      if (optionToUpdate) {
        optionToUpdate.dataColumnName = action.payload.newValue;
      }
    }
  }
};

// Ranking

const addRankingChoice = (state: IQuestionData, action: IAction<{ path: string; choice: IRankItem }>) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question && question.ranks) {
    question.ranks.choices = [...(question.ranks.choices || []), action.payload.choice];
  }
};

const removeRankingChoice = (state: IQuestionData, action: IAction<{ path: string; choiceId: string }>) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question && question.ranks) {
    question.ranks.choices = question.ranks.choices.filter((choice) => choice.id !== action.payload.choiceId);
  }
};

const updateRankingChoiceLabel = (
  state: IQuestionData,
  action: IAction<{ path: string; choiceId: string; label: string }>,
) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question && question.ranks) {
    const choice = question.ranks.choices.find((choice) => choice.id === action.payload.choiceId);
    if (choice) {
      choice.label = action.payload.label;
    }
  }
};

const updateRankingChoiceValue = (
  state: IQuestionData,
  action: IAction<{ path: string; choiceId: string; value: string }>,
) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question && question.ranks) {
    const choice = question.ranks.choices.find((choice) => choice.id === action.payload.choiceId);
    if (choice) {
      choice.value = action.payload.value;
    }
  }
};

const addRankingItem = (state: IQuestionData, action: IAction<{ path: string; item: IRankItem }>) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question && question.ranks) {
    question.ranks.items = [...(question.ranks.items || []), action.payload.item];
  }
};

const removeRankingItem = (state: IQuestionData, action: IAction<{ path: string; itemId: string }>) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question && question.ranks) {
    question.ranks.items = question.ranks.items.filter((item) => item.id !== action.payload.itemId);
  }
};

const updateRankingItemLabel = (
  state: IQuestionData,
  action: IAction<{ path: string; itemId: string; label: string }>,
) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question && question.ranks) {
    const item = question.ranks.items.find((item) => item.id === action.payload.itemId);
    if (item) {
      item.label = action.payload.label;
    }
  }
};

const updateRankingItemValue = (
  state: IQuestionData,
  action: IAction<{ path: string; itemId: string; value: string }>,
) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question && question.ranks) {
    const item = question.ranks.items.find((item) => item.id === action.payload.itemId);
    if (item) {
      item.value = action.payload.value;
    }
  }
};

// Range

const updateRangeItem = (
  state: IQuestionData,
  action: IAction<{ path: string; key: 'start' | 'end' | 'step'; value: number }>,
) => {
  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question && question.range) {
    question.range[action.payload.key] = action.payload.value;
  }
};

// Choice group - Translation
const updateChoiceOption = (
  state: IQuestionData,
  action: IAction<{ path: string; optionId: string; languageCode: string; label: string }>,
) => {
  const question = getQuestionByPath(state.questions, action.payload.path);
  console.log('question paise', action.payload);

  if (question && question.choice) {
    console.log('question paise', question);
    // Find the option by UUID in the question's choice options
    const option = question.choice.options.find((opt: any) => opt.uuid === action.payload.optionId);

    if (option) {
      console.log('asdfsd', option);
      // Update the option label for the specified language code
      option.option = {
        ...option.option,
        [action.payload.languageCode]: action.payload.label,
      };
    }
  }
};

export default {
  add,
  rearrange,
  selectQuestion,
  update,
  closeSettingsTab,
  toggleSettingsTab,
  addToMultiSelection,
  stopMultiSelection,
  deleteQuestion,
  setQuestions,
  updateQuestionLabel,
  updateQuestionHint,
  updateQuestionMandatoryResponse,
  updateQuestionDefaultResponse,
  updateQuestionHXLTag,
  updateQuestionHXLTagValue,
  updateQuestionApperance,
  updateQuestionParameter,
  updateQuestionSeed,
  updateQuestionListName,
  updateQuestionName,
  updateQuestionDefaultLogic,
  removeAllSkipLogic,
  updateSkipLogicValue,
  updateSkipLogicCondition,
  updateSkipLogicName,
  updateSkipLogic,
  addSkipLogic,
  removeSkipLogic,
  updateQuestionChoiceOption,
  updateChoiceOptionName,
  updateDependencyQuestion,
  updateChoiceOptionValue,
  addChoiceOption,
  removeChoiceOption,
  removeSelection,
  updateManualSkipLogic,
  updateManualValidation,
  updateErrorMessage,
  addMatrixRow,
  removeMatrixRow,
  updateMatrixRowLabel,
  addMatrixColumn,
  removeMatrixColumn,
  updateMatrixColumnLabel,
  updateColumnExpand,
  updateMatrixLabel,
  updateMatrixRowPrefix,
  updateMatrixColumnResponseType,
  updateMatrixDataColumnSuffix,
  addMatrixChoiceOption,
  removeMatrixChoiceOption,
  updateMatrixChoiceOptionLabel,
  updateMatrixChoiceOptionValue,
  addRatingColumn,
  addRatingRow,
  removeRatingRow,
  removeRatingColumn,
  updateRatingColumnTitle,
  updateRatingColumnSubtitle,
  updateRatingRowTitle,
  updateRatingRowSubtitle,
  addRankingChoice,
  removeRankingChoice,
  updateRankingChoiceLabel,
  updateRankingChoiceValue,
  addRankingItem,
  removeRankingItem,
  updateRankingItemLabel,
  updateRankingItemValue,
  updateRangeItem,
  addValidationCondition,
  removeValidationCondition,
  updateValidationCondition,
  updateValidationConditionValue,
  clearValidation,
  updateChoiceOption,
};
