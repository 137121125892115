import { Box } from '@material-ui/core';
import Style from './Style';
import RenderValidation from './RenderValidation';
import React from 'react';
import {
  ACKNOWLEDGE,
  AREA,
  AUDIO,
  MULTIPLE_SELECT,
  NUMBER,
  PHOTO,
  SELECT_ONE,
  TEXT,
  VIDEO,
} from '../../QuesTypes/Constants';
import ManuallyValidation from './ManuallyValidation';
import { validationDefaultValue } from './utils';
import { deepClone, getQuestionByPath } from '../../../../helpers/utils';
import { useSelector } from 'react-redux';
import { IQuestion, MainState, ILanguage } from '../../../../types/state';

export default function ValidationCriteria(props: any) {
  const classes = Style();
  const questions: IQuestion[] = useSelector((state: MainState) => state.question.questions);

  const selectedQuestion: IQuestion = useSelector((state: MainState) => state.question.selectedQuestion);
  const currentQuestion = getQuestionByPath(questions, selectedQuestion.path);

  const onNestedQuestionChange = (data: any, index: any, value: any, child: any) => {
    const ques = deepClone(props.question);
    const event: any = { ...data, [child]: value, ['bangla']: value };
    ques.validation.splice(index, 1, event);
    props.onChange(ques);
  };

  const onQuestionObjectChange = (e: any, parent: any, code: any) => {
    props.onChange({ ...props.question, [parent]: { ...props.question[parent], [code]: e.target.value } });
  };

  const addValidation = () => {
    const ques = deepClone(props.question);
    ques.validation = [...ques.validation, validationDefaultValue];
    if (ques.validation.length > 1) {
      ques.validationCriteria = 'all';
    }
    props.onChange(ques);
    // props.onLogicChange(ques);
  };

  const removeValidation = (index: number) => {
    const ques = deepClone(props.question);
    ques.validation.splice(index, 1);

    if (ques.validation.length === 0) {
      delete ques.validationCriteria;
    }
    props.onChange(ques);
  };

  const applicableForManual = () => {
    return [SELECT_ONE, MULTIPLE_SELECT, PHOTO, AUDIO, VIDEO, ACKNOWLEDGE, AREA].includes(currentQuestion.type);
  };
  return (
    <>
      <Box className={classes.validationBox}>
        {applicableForManual() ? (
          <ManuallyValidation hideCloseBtn {...props} />
        ) : (
          <RenderValidation
            {...props}
            removeValidation={removeValidation}
            addValidation={addValidation}
            onChange={onNestedQuestionChange}
            onQuestionObjectChange={onQuestionObjectChange}
          />
        )}
        <>
          <br />

          {/* <Box className={classes.errorMsgSection}>
            <Form.Item
              style={{ width: '100%' }}
              name="errorMessage"
              label="Error Message:"
              rules={[{ required: true }]}
            >
              <Input
                defaultValue={props.question.errorMsg}
                onChange={(e: any) => props.onChange({ ...props.question, errorMsg: e.target.value })}
              />
            </Form.Item>
          </Box> */}
        </>
      </Box>
    </>
  );
}
