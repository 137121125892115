import { createSlice } from '@reduxjs/toolkit';
import GroupService from './GroupService';
import { questionDefaultValue } from '../features/FormDesigner/Questions/Constants';
import QuestionService from './QuestionService';

export const questionSlice = createSlice({
  name: 'question',
  initialState: {
    questions: [],
    selectedQuestion: questionDefaultValue,
    showSettingsTab: false,
    multiselection: {
      onGoing: false,
      pathList: [],
    },
  },
  reducers: {
    ...GroupService,
    ...QuestionService,
  },
});

export const {
  add,
  rearrange,
  update,
  selectQuestion,
  closeSettingsTab,
  addToMultiSelection,
  stopMultiSelection,
  createGroup,
  updateGroup,
  deleteQuestion,
  deleteGroup,
  setQuestions,
  updateQuestionLabel,
  updateQuestionHint,
  toggleSettingsTab,
  updateGroupLabel,
  updateGroupName,
  toggleGroupApperance,
  toggleRepeat,
  updateQuestionMandatoryResponse,
  updateQuestionDefaultResponse,
  updateQuestionHXLTag,
  updateQuestionHXLTagValue,
  updateQuestionApperance,
  updateQuestionParameter,
  updateQuestionSeed,
  updateQuestionListName,
  updateQuestionName,
  updateQuestionDefaultLogic,
  removeAllSkipLogic,
  updateSkipLogicValue,
  updateSkipLogicCondition,
  updateSkipLogicName,
  updateSkipLogic,
  addSkipLogic,
  removeSkipLogic,
  updateQuestionChoiceOption,
  updateChoiceOptionName,
  updateDependencyQuestion,
  updateChoiceOptionValue,
  addChoiceOption,
  removeChoiceOption,
  removeSelection,
  updateManualSkipLogic,
  updateManualValidation,
  updateErrorMessage,
  addMatrixRow,
  removeMatrixRow,
  updateMatrixRowLabel,
  addMatrixColumn,
  removeMatrixColumn,
  updateMatrixColumnLabel,
  updateMatrixLabel,
  updateMatrixRowPrefix,
  updateMatrixColumnResponseType,
  updateMatrixDataColumnSuffix,
  addMatrixChoiceOption,
  removeMatrixChoiceOption,
  updateMatrixChoiceOptionLabel,
  updateMatrixChoiceOptionValue,
  addRatingColumn,
  addRatingRow,
  removeRatingRow,
  removeRatingColumn,
  updateRatingColumnTitle,
  updateRatingColumnSubtitle,
  updateRatingRowTitle,
  updateRatingRowSubtitle,
  addRankingChoice,
  removeRankingChoice,
  updateRankingChoiceLabel,
  updateRankingChoiceValue,
  addRankingItem,
  removeRankingItem,
  updateRankingItemLabel,
  updateRankingItemValue,
  updateRangeItem,
  addValidationCondition,
  removeValidationCondition,
  updateValidationCondition,
  updateValidationConditionValue,
  clearValidation,
  updateChoiceOption,
} = questionSlice.actions;

export const userSelector = (state: any) => state.user;

export default questionSlice.reducer;
