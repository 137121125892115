import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import Style, { getImageByType } from './Style';
import { Box, Grid, IconButton, Stack, TextField, Tooltip, Typography } from '@mui/material';
import QuestionAddTool from '../../../components/Poper';
import { useDispatch, useSelector } from 'react-redux';
import {
  add,
  addToMultiSelection,
  closeSettingsTab,
  deleteQuestion,
  selectQuestion,
  toggleSettingsTab,
  updateQuestionHint,
  updateQuestionLabel,
} from '../../../redux/QuestionSlice';
import QuestionConfig from '../Settings';
import { CopyOutlined, DeleteOutlined, FileAddOutlined, SettingOutlined } from '@ant-design/icons';
import { addToQuesBank } from '../../../redux/QuesBankSlice';
import { ILanguage, IQuestion, MainState } from '../../../types/state';
import { v4 as uuidv4 } from 'uuid';
import Slide from '@mui/material/Slide';
import toast from 'react-hot-toast';
import {
  findDependentQuestion,
  findQuestionByKey,
  getLocalizedText,
  getQuestionByPath,
  isQuestionDependent,
} from '../../../helpers/utils';
import { useTheme } from '@material-ui/core/styles';

type QuestionItemPropsType = {
  questionItem: IQuestion;
};

export default function QuestionItem({ questionItem }: QuestionItemPropsType) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const toggleAnchor = () => setAnchorVisibility(false);
  const boxRef = useRef<HTMLDivElement>(null);
  const questions: IQuestion[] = useSelector((state: MainState) => state.question.questions);

  const defaultLanguage: ILanguage = useSelector((state: MainState) => state.language.defaultLanguage);
  const selectedQuestion: IQuestion = useSelector((state: MainState) => state.question.selectedQuestion);
  const currentQuestion = getQuestionByPath(questions, selectedQuestion.path);
  const multiselection = useSelector((state: MainState) => state.question.multiselection);

  const classes = Style();
  const [anchorVisibility, setAnchorVisibility] = useState<boolean>(false);
  const [anchor, setAnchor] = useState<any>(null);
  const [iconHover, setIconHover] = useState({ bg: 'white', text: '' });

  const [isEditingLabel, setIsEditingLabel] = useState(false);
  const [isEditingHint, setIsEditingHint] = useState(false);

  const [showAddButton, setShowAddButton] = useState(false);

  const openAnchor = (event: SyntheticEvent) => {
    setAnchor(event.currentTarget);
    setAnchorVisibility(true);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (boxRef.current && !boxRef.current.contains(event.target as Node)) {
      if (!selectedQuestion || selectedQuestion.id !== questionItem.id) {
        // dispatch(removeSelection());
      }
      // dispatch(removeSelection());
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [selectedQuestion]);

  const setSelectedQuestion = (e: any) => {
    if (e.ctrlKey) {
      if (selectedQuestion && selectedQuestion.path && !multiselection.onGoing) {
        dispatch(addToMultiSelection(selectedQuestion.path));
      }
      dispatch(addToMultiSelection(questionItem.path));
    } else {
      dispatch(selectQuestion(questionItem));
    }
  };

  const isActive = () => {
    if (multiselection.onGoing) {
      return multiselection.pathList.includes(questionItem.path);
    }
    return selectedQuestion?.id === questionItem?.id;
  };

  const onSettingsClick = (e: SyntheticEvent) => {
    dispatch(toggleSettingsTab());
  };

  const onDelete = (e: SyntheticEvent) => {
    const dependencyQuestion = findDependentQuestion(questionItem.id, questions);
    if (!dependencyQuestion) {
      e.stopPropagation();
      const yes = confirm(`Are you sure you want to delete ? \n ${questionItem.name}`);
      if (yes) {
        dispatch(deleteQuestion(questionItem));
        dispatch(closeSettingsTab());
      }
    } else {
      toast.error(`Can not delete. It depends on ${dependencyQuestion}`);
    }
  };

  const addToQuestionBank = (e: SyntheticEvent) => {
    e.stopPropagation();
    dispatch(addToQuesBank(questionItem));
    toast.success('Question has been added');
  };

  const duplicateQuestion = (event: React.SyntheticEvent, questionItem: IQuestion) => {
    event.stopPropagation();

    if (questionItem) {
      const duplicatedQuestion = {
        ...questionItem,
        id: uuidv4(),
      };
      dispatch(add(duplicatedQuestion));
    }
  };

  const handleLabelEdit = () => {
    setIsEditingLabel(!isEditingLabel);
  };

  const handleLabel = (value: string) => {
    dispatch(
      updateQuestionLabel({
        label: value,
        languageCode: defaultLanguage.code,
        path: questionItem.path,
      }),
    );
  };

  const handleHintEdit = (event: React.SyntheticEvent) => {
    setIsEditingHint(!isEditingHint);
  };

  const handleHint = (value: string) => {
    dispatch(
      updateQuestionHint({
        hint: value,
        languageCode: defaultLanguage.code,
        path: questionItem.path,
      }),
    );
  };

  const containerRef = React.useRef<HTMLElement>(null);

  useEffect(() => {
    if (!showAddButton) setAnchorVisibility(false);
  }, [showAddButton]);

  return (
    <Slide in container={containerRef.current}>
      <Box className="drag-handle" ref={boxRef}>
        <Box sx={{ position: 'relative' }}>
          <Box
            sx={{
              display: `${showAddButton ? 'flex' : 'none'}`,
              // border: '1px solid red',
              height: '100%',
              position: 'absolute',
              paddingRight: 2,
              left: -45,
              bottom: -20,
              flexDirection: 'column',
              justifyContent: 'flex-end',
            }}
            onMouseEnter={() => setShowAddButton(true)}
            onMouseLeave={() => setShowAddButton(false)}
            id="popover-anchor"
            onClick={openAnchor}
          >
            <Tooltip placement="top" title="Add A question">
              <IconButton
                color="info"
                sx={{
                  // borderBottomLeftRadius: '10px',
                  borderRadius: '5px',
                  color: theme.palette.text.secondary,
                  borderColor: theme.palette.info.main,
                  background: theme.palette.info.light,
                  '&:hover ': { background: theme.palette.info.main },
                }}
                size="small"
                onClick={openAnchor}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="icon icon-tabler icons-tabler-outline icon-tabler-plus"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 5l0 14" />
                  <path d="M5 12l14 0" />
                </svg>
              </IconButton>
            </Tooltip>
          </Box>
          <Box
            onClick={setSelectedQuestion}
            onMouseEnter={() => setShowAddButton(true)}
            onMouseLeave={() => setShowAddButton(false)}
            sx={(theme) => ({
              border: isActive() ? '1px solid #2196f3' : 'none',
              width: '100%',
              boxShadow: isActive() ? '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)' : 'none',
              height: isActive() ? 'auto' : '100%',
              backgroundColor: iconHover.bg ? iconHover.bg : isActive() ? 'rgb(251, 394, 251)' : 'lightgray',
              borderRadius: '5px',
              padding: '8px',
            })}
          >
            {/* <Stack direction={'row'} sx={{ height: '100%' }} gap={1}>
              <Box sx={{ background: 'rgba(227, 242, 253, 0.28)', paddingX: 4, borderRadius: '10px' }}>
                <Stack alignItems={'center'} direction={'row'} sx={{ height: '100%' }}>
                  {questionItem.type === '' ? (
                    <p>
                      This form is currently empty. You can add questions, notes, prompts, or other fields by clicking
                      on the + sign below.
                    </p>
                  ) : (
                    <Box>
                      <img alt="Img" src={getImageByType(questionItem.type)} height="auto" width="32" />
                    </Box>
                  )}
                </Stack>
              </Box>

              <Box sx={{ background: 'rgba(227, 242, 253, 0.28)', borderRadius: '10px' }} flexGrow={1}>
                <Stack alignItems={'center'} direction={'row'} sx={{ height: '100%' }}>
                  <Grid container marginLeft={4}>
                    <Grid item xs={12} sx={{ cursor: 'pointer' }} className="no-drag">
                      {isEditingLabel ? (
                        <TextField
                          size="small"
                          value={currentQuestion && getLocalizedText(currentQuestion.label, defaultLanguage.code)}
                          onChange={(e) => handleLabel(e.target.value)}
                          label="Question Label"
                          // onMouseDown={(e) => e.stopPropagation()}
                          onBlur={handleLabelEdit}
                          autoFocus
                        />
                      ) : (
                        <Typography variant="subtitle2" onClick={handleLabelEdit}>
                          {getLocalizedText(questionItem.label, defaultLanguage.code) || 'New Question'}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item sx={{ cursor: 'pointer' }} className="no-drag">
                      {isEditingHint ? (
                        <TextField
                          size="small"
                          value={currentQuestion && getLocalizedText(currentQuestion.hint, defaultLanguage.code)}
                          onChange={(e) => handleHint(e.target.value)}
                          label="Hint"
                          onBlur={handleHintEdit}
                          autoFocus
                        />
                      ) : (
                        <Typography variant="caption" onClick={handleHintEdit}>
                          {getLocalizedText(questionItem.hint, defaultLanguage.code) || 'Question hint'}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Typography variant="h5" style={{ color: 'gray', fontWeight: 700 }}>
                    {iconHover.text}
                  </Typography>
                </Stack>
              </Box>
              <Box sx={{ background: 'rgba(227, 242, 253, 0.28)', paddingX: 2, borderRadius: '10px' }}>
                <Stack alignItems={'center'} direction={'row'} sx={{ height: '100%' }}>
                  <div className={classes.iconSection}>
                    {questionItem.type !== '' && (
                      <>
                        <Tooltip title="Settings" placement="right" arrow>
                          <span
                            onMouseEnter={() => setIconHover({ bg: '#BDBDBD99', text: 'Settings' })}
                            onMouseLeave={() => setIconHover({ bg: 'white', text: '' })}
                          >
                            <SettingOutlined className={classes.settingsIcon} onClick={onSettingsClick} />
                          </span>
                        </Tooltip>

                        <Tooltip title="Delete" placement="right" arrow>
                          <span
                            onMouseEnter={() => setIconHover({ bg: '#EF535099', text: 'Delete Question' })}
                            onMouseLeave={() => setIconHover({ bg: 'white', text: '' })}
                          >
                            <DeleteOutlined className={classes.deleteIcon} onClick={onDelete} />
                          </span>
                        </Tooltip>

                        <Tooltip title="Duplicate" placement="right" arrow>
                          <span
                            onMouseEnter={() => setIconHover({ bg: '#4CAF5099', text: 'Duplicate Question' })}
                            onMouseLeave={() => setIconHover({ bg: 'white', text: '' })}
                          >
                            <CopyOutlined
                              className={classes.copyIcon}
                              onClick={(event: React.SyntheticEvent) => duplicateQuestion(event, questionItem)}
                            />
                          </span>
                        </Tooltip>
                        <Tooltip title="Add" placement="right" arrow>
                          <span
                            onMouseEnter={() => setIconHover({ bg: 'lightblue', text: 'Add to Question Bank' })}
                            onMouseLeave={() => setIconHover({ bg: 'white', text: '' })}
                          >
                            <FileAddOutlined className={classes.quesBankIcon} onClick={addToQuestionBank} />
                          </span>
                        </Tooltip>
                      </>
                    )}
                  </div>
                </Stack>
              </Box>
            </Stack> */}
            <Stack direction={'row'} sx={{ height: { xs: '70px', md: '85px', lg: '100px' } }} gap={1}>
              <Box
                sx={{
                  background: 'rgba(227, 242, 253, 0.28)',
                  paddingX: 2,
                  borderRadius: '10px',
                  height: '100%',
                }}
              >
                <Stack alignItems={'center'} direction={'row'} sx={{ height: '100%' }}>
                  {questionItem.type === '' ? (
                    <p style={{ fontSize: '12px' }}>
                      This form is currently empty. You can add questions, notes, prompts, or other fields by clicking
                      on the + sign below.
                    </p>
                  ) : (
                    <Box>
                      <img alt="Img" src={getImageByType(questionItem.type)} height="24" width="24" />
                    </Box>
                  )}
                </Stack>
              </Box>

              <Box
                sx={{
                  background: 'rgba(227, 242, 253, 0.28)',
                  borderRadius: '10px',
                  height: '100%',
                }}
                flexGrow={1}
              >
                <Stack alignItems={'center'} direction={'row'} sx={{ height: '100%' }}>
                  <Grid container marginLeft={2}>
                    <Grid item xs={12} sx={{ cursor: 'pointer' }} className="no-drag">
                      {isEditingLabel ? (
                        <TextField
                          size="small"
                          value={currentQuestion && getLocalizedText(currentQuestion.label, defaultLanguage.code)}
                          onChange={(e) => handleLabel(e.target.value)}
                          label="Question Label"
                          onBlur={handleLabelEdit}
                          autoFocus
                        />
                      ) : (
                        <Typography variant="subtitle2" onClick={handleLabelEdit} color="dimgray">
                          {getLocalizedText(questionItem.label, defaultLanguage.code) || 'New Question'}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item sx={{ cursor: 'pointer' }} className="no-drag">
                      {isEditingHint ? (
                        <TextField
                          size="small"
                          value={currentQuestion && getLocalizedText(currentQuestion.hint, defaultLanguage.code)}
                          onChange={(e) => handleHint(e.target.value)}
                          label="Hint"
                          onBlur={handleHintEdit}
                          autoFocus
                        />
                      ) : (
                        <Typography variant="caption" onClick={handleHintEdit} fontStyle="italic">
                          {getLocalizedText(questionItem.hint, defaultLanguage.code) || 'Question hint'}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Typography variant="body2" style={{ color: 'gray', fontWeight: 700, fontSize: '10px' }}>
                    {iconHover.text}
                  </Typography>
                </Stack>
              </Box>

              <Box
                sx={{
                  background: 'rgba(227, 242, 253, 0.28)',
                  paddingX: 1,
                  borderRadius: '10px',
                  height: '100%',
                }}
              >
                <Stack alignItems={'center'} direction={'row'} sx={{ height: '100%' }}>
                  <div className={classes.iconSection}>
                    {questionItem.type !== '' && (
                      <>
                        <Tooltip title="Settings" placement="right" arrow>
                          <SettingOutlined
                            onMouseEnter={() => setIconHover({ bg: '#BDBDBD99', text: 'Settings' })}
                            onMouseLeave={() => setIconHover({ bg: 'white', text: '' })}
                            className={classes.settingsIcon}
                            style={{ fontSize: '16px' }}
                            onClick={onSettingsClick}
                          />
                        </Tooltip>

                        <Tooltip title="Delete" placement="right" arrow>
                          <DeleteOutlined
                            onMouseEnter={() => setIconHover({ bg: '#EF535099', text: 'Delete Question' })}
                            onMouseLeave={() => setIconHover({ bg: 'white', text: '' })}
                            className={classes.deleteIcon}
                            style={{ fontSize: '16px' }}
                            onClick={onDelete}
                          />
                        </Tooltip>

                        <Tooltip title="Duplicate" placement="right" arrow>
                          <CopyOutlined
                            onMouseEnter={() => setIconHover({ bg: '#4CAF5099', text: 'Duplicate Question' })}
                            onMouseLeave={() => setIconHover({ bg: 'white', text: '' })}
                            className={classes.copyIcon}
                            style={{ fontSize: '16px' }}
                            onClick={(event: React.SyntheticEvent) => duplicateQuestion(event, questionItem)}
                          />
                        </Tooltip>

                        <Tooltip title="Add" placement="right" arrow>
                          <FileAddOutlined
                            onMouseEnter={() => setIconHover({ bg: 'lightblue', text: 'Add to Question Bank' })}
                            onMouseLeave={() => setIconHover({ bg: 'white', text: '' })}
                            className={classes.quesBankIcon}
                            style={{ fontSize: '16px' }}
                            onClick={addToQuestionBank}
                          />
                        </Tooltip>
                      </>
                    )}
                  </div>
                </Stack>
              </Box>
            </Stack>
          </Box>
        </Box>

        <Box onMouseEnter={() => setShowAddButton(true)} onMouseLeave={() => setShowAddButton(false)}>
          <QuestionAddTool
            open={anchorVisibility}
            anchorEl={anchor}
            toggleAnchor={toggleAnchor}
            currentQuestion={questionItem}
          />
        </Box>
        {isActive() ? <QuestionConfig questionItem={questionItem} /> : null}
      </Box>
    </Slide>
  );
}
