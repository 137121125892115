import React from 'react';
import { Grid, Paper, Typography, Collapse } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { CaretDownOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { MainState } from '../../../../../types/state';
import { getQuestionByPath } from '../../../../../helpers/utils';
import { updateChoiceOption } from '../../../../../redux/QuestionSlice';

const ChoiceGroup = (props: any) => {
  const dispatch = useDispatch();
  const defaultLanguage = useSelector((state: MainState) => state.language.defaultLanguage);
  const selectedLanguage = useSelector((state: MainState) => state.language.selectedLanguageList);
  const questions = useSelector((state: MainState) => state.question.questions);
  const currentQuestion = getQuestionByPath(questions, props.question.path);

  return (
    <Grid container sx={{ p: 2 }}>
      <Paper sx={{ borderTop: 1, borderColor: 'grey.300', width: '100%', mr: '20px', pb: 1 }} elevation={2}>
        <Grid item style={{ color: 'grey' }}>
          <Grid
            container
            alignItems="center"
            direction="row"
            aria-label="settings"
            onClick={() => props.setCollapseChoice(!props.collapseChoice)}
          >
            <Typography style={{ color: 'grey', fontSize: 15 }} sx={{ p: 2 }}>
              Choice
            </Typography>
            <CaretDownOutlined />
          </Grid>
          <Collapse in={!props.collapseChoice}>
            <Grid container>
              <Grid item xs={12}>
                {currentQuestion.choice.isDefault && (
                  <Grid container justifyContent="center">
                    {currentQuestion.choice.options &&
                      currentQuestion.choice.options.map((data: any, index: any) => {
                        return (
                          <Paper
                            sx={{ margin: '20px', width: '90%', p: 3, borderTop: 1, borderColor: 'grey.400' }}
                            key={index}
                            elevation={1}
                          >
                            <Grid container spacing={2} alignItems="center">
                              <Grid item xs={12} md={6}>
                                <Typography variant="subtitle2">
                                  {`Option (${defaultLanguage.code} - default)`}
                                </Typography>
                                <Input
                                  name="option"
                                  value={data.option[defaultLanguage.code]}
                                  onChange={(e) =>
                                    dispatch(
                                      updateChoiceOption({
                                        path: currentQuestion.path,
                                        optionId: data.uuid,
                                        languageCode: defaultLanguage.code,
                                        label: e.target.value,
                                      }),
                                    )
                                  }
                                  // onChange={(e: any) => props.optionValueChange(index, e, defaultLanguage.code)}
                                  style={{ width: '100%' }}
                                />
                              </Grid>
                              {selectedLanguage.map((lang: any, i: number) => (
                                <Grid item xs={12} md={6} key={i}>
                                  <Typography variant="subtitle2">{`Option (${lang.code})`}</Typography>
                                  <Input
                                    name="option"
                                    value={data.option[lang.code]}
                                    // onChange={(e: any) => props.optionValueChange(index, e, lang.code)}
                                    onChange={(e) =>
                                      dispatch(
                                        updateChoiceOption({
                                          path: currentQuestion.path,
                                          optionId: data.uuid,
                                          languageCode: lang.code,
                                          label: e.target.value,
                                        }),
                                      )
                                    }
                                    style={{ width: '100%' }}
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          </Paper>
                        );
                      })}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default ChoiceGroup;
